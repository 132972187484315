import React, { useEffect } from "react";
import "./style.css";
import logo from "../../assets/images/gigmi-logo.svg";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Title from "../../components/DepositInstructions/Title";
import Paragraph from "../../components/DepositInstructions/Paragraph";
import InformationListItem from "../../components/DepositInstructions/InformationListItem";

const DepositInstructions = () => {
  const param = useParams();
  const { lang } = param;

  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
  
    if (lang === "es" && storedLang !== "true") {
      localStorage.setItem("lang", "true");
      window.location.reload(); 
    } else if (lang !== "es" && storedLang === "true") {
      localStorage.removeItem("lang");
      window.location.reload(); 
    }
  }, [lang]); 

  return (
    <div style={{ backgroundColor: "#F77E0B" }}>
      <div
        style={{ backgroundColor: "#F77E0B", height: "100%" }}
        className="container pt-5"
      >
        <div className=" top-bar w-100">
          <div className="d-flex justify-content-center w-100">
            <img src={logo} alt="" />
          </div>
        </div>

        <div
          className="shadow  p-5 mt-3 "
          style={{
            paddingTop: "4rem",
            overflowX: "hidden",
            borderRadius: "20px",
            backgroundColor: "white",
          }}
        >
          <div className="row">
            <div className="col-sm-12">
              <div>
                <p>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      <b>
                        <FormattedMessage id="depositInstructions" />
                      </b>
                    </span>
                  </div>
                </p>
                <Paragraph id="dp1"></Paragraph>
                <p></p>
                <Paragraph id="dp2"></Paragraph>
                <p></p>

                <p></p>
                <Paragraph id="dp3"></Paragraph>
                <p></p>
                <Title id="dh1"></Title>
                <Paragraph id="dp4"></Paragraph>
                <p></p>
                <Title id="dh2"></Title>
                <p></p>
                <Paragraph id="dp5"></Paragraph>

                <Paragraph id="dp6"></Paragraph>
                <p></p>
                <Title id="dh3"></Title>
                <p></p>
                <Paragraph id="dp7"></Paragraph>

                <p></p>
                <Title id="dh4"></Title>
                <p></p>
                <Paragraph id="dp8"></Paragraph>

                <p></p>
                <Title id="dh5"></Title>
                <p></p>
                <Paragraph id="dp9"></Paragraph>

                <p></p>
                <Title id="dh6"></Title>
                <p></p>
                <Paragraph id="dp10"></Paragraph>
                <InformationListItem id="dl1"></InformationListItem>
                <InformationListItem id="dl2"></InformationListItem>
                <InformationListItem id="dl3"></InformationListItem>
                <InformationListItem id="dl4"></InformationListItem>
                <InformationListItem id="dl5"></InformationListItem>
                <InformationListItem id="dl6"></InformationListItem>
                <InformationListItem id="dl7"></InformationListItem>
                <InformationListItem id="dl8"></InformationListItem>
                <InformationListItem id="dl9"></InformationListItem>

                <Title id="dh7"></Title>
                <p></p>
                <Paragraph id="dp11"></Paragraph>

                <Title id="dh8"></Title>
                <p></p>
                <Paragraph id="dp12"></Paragraph>

                <p></p>
                <Title id="dh9"></Title>
                <p></p>
                <Paragraph id="dp13"></Paragraph>

                <p></p>
                <Title id="dh10"></Title>
                <p></p>
                <Paragraph id="dp14"></Paragraph>

                <p></p>
                <Title id="dh11"></Title>
                <p></p>
                <Paragraph id="dp15"></Paragraph>

                <p></p>
                <Title id="dh12"></Title>
                <p></p>
                <Paragraph id="dp16"></Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositInstructions;
